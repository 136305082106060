import styled from 'styled-components';

const Wrapper = styled.div`
  background: #f7f7f7;
`;

const Container = styled.div`
  height: 6rem;
  padding: 1rem;
  font-size: 0.9rem;
  color: black;
  border: none;

  @media (max-width: 320px) {
    font-size: 0.8rem;    
  }
`;

const ContentsInfo = () => (
  <Wrapper>
    <Container>
      사업자등록번호: 769-88-00790<br />
      법인명: 주식회사 연익<br />
      주소: 청주시 상당구 동남지구 상업1블럭2로트<br />
      대표: 김인홍<br />
      대표번호: 010-3404-3148<br />
    </Container>
  </Wrapper>
);

export default ContentsInfo;
