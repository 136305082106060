import styled from 'styled-components';
import { Button } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';

import { darkGrey } from '../../styles/colors';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

const StyledSpan = styled.span`
  display: flex;
  align-items: center;
  margin-right: 0.5rem;
  color: ${({ success }) => success ? 'green' : 'red'};
  font-size: 0.8rem;

  span {
    display: inline-block;
    @media (max-width: 480px) {
      display: none;
    }
  }
`;

const StyledButton = styled(Button)`
  && {
    width: 7rem;
    height: 2rem;
    text-align: center;
    margin-right: 0.5rem;
    padding: 0.5rem 0.7rem;
    font-size: 1rem;
    font-weight: 600;
    color: ${({ sticky }) => (sticky ? 'white' : darkGrey)};
    border: 2px solid ${darkGrey};
    border-radius: 2rem;
    background: ${({ sticky }) => (sticky ? darkGrey : 'auto')};
    border-color: ${({ sticky }) => (sticky ? darkGrey : 'auto')};
    cursor: pointer;

    &:hover {
      color: white;
      background: ${darkGrey};
      border-color: ${darkGrey};
      transform: scale(1.1);
      transition: all .2s ease-in-out;
    }
  }
`;

export const toSendEmail = () => {
  document.getElementById('to-send-email').scrollIntoView({ block: 'start', behavior: 'smooth' });
};

const SendEmail = ({ sticky, isSubmit, handleSubmit }) => {
  return (
    <Wrapper>
      {isSubmit !== null && (
        isSubmit ? (
          <StyledSpan success>
            <span>빠른 시일 안에 답변드리겠습니다</span>
            <DoneIcon />
          </StyledSpan>
        ) : (
          <StyledSpan>
            <span>문의 요청에 오류가 생겼습니다. 잠시 후, 다시 문의해주세요</span>
            <ErrorIcon />
          </StyledSpan>
        )
      )}
      <StyledButton
        onClick={() => [handleSubmit(null), toSendEmail()]}
        sticky={sticky ? 1 : 0}
      >
        문의하기
      </StyledButton>
    </Wrapper>
  );
};

export default SendEmail;
