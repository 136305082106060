import styled, { keyframes } from 'styled-components';

const loadingKeyframe = keyframes`
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`;

const Container = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 0.3rem;
`;

const Loader = styled.div`
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 1.5rem;
  height: 1.5rem;
  margin: 3px;
  border: 3px solid black;
  border-radius: 50%;
  animation: ${loadingKeyframe} 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: black transparent transparent transparent;
`;

const Loader1 = styled(Loader)`
  animation-delay: -0.45s;
`;

const Loader2 = styled(Loader)`
  animation-delay: -0.3s;
`;

const Loader3 = styled(Loader)`
  animation-delay: -0.15s;
`;

const StyledDiv = styled.div`
  margin-top: 4rem;
`;

const Spinner = () => (
  <Container>
    <Loader />
    <Loader1 />
    <Loader2 />
    <Loader3 />
    <StyledDiv>전송 중...</StyledDiv>
  </Container>
)

export default Spinner;
