import styled from 'styled-components';
import { AppBar, Tabs, Tab, Typography } from '@material-ui/core';
import { containerWidth } from '../styles/layout';

import {
  lightGrey,
  darkGrey,
} from '../styles/colors';

const Wrapper = styled.div`
  margin: 0 auto;
  border-top: 1px solid ${lightGrey};
`;

const Container = styled.div`
  width: ${containerWidth};
  margin: 0 auto;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const StyledH2 = styled.h2`
  font-size: 2rem;
  color: ${darkGrey};
  margin-top: 2rem;

  @media (max-width: 874px) {
    margin-bottom: 1.3rem;
    font-size: 1.5rem;
  }

  @media (max-width: 320px) {
    font-size: 1rem;    
  }
`;

const Overview = styled.p`
  margin: 0 auto 3rem auto;
  font-size: 0.9rem;
  line-height: 1.4;
  color: ${darkGrey};

  @media (max-width: 480px) {
    font-size: 0.85rem;    
  }

  @media (max-width: 320px) {
    font-size: 0.7rem;    
  }
`;

const StyledDiv = styled.div`
  width: 80rem;
  display: flex;
  justify-content: center;
  padding-bottom: 2.5rem;

  @media (max-width: 874px) {
    width: 100%;
    flex-direction: column;
    align-items: center;
  }
`;

const LocationImg = styled.div`
  width: 45rem;
  height: 15rem;
  border-radius: 2rem;
  box-shadow: 0 3px 20px rgba(0,0,0,0.12), 0 3px 20px rgba(0,0,0,0.13);

  @media (max-width: 874px) {
    width: 80%;
  }

  @media (max-width: 480px) {
    width: 90%;
  }
`;

const Style = styled.div`
  height: 12rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 3rem;
  color: white;
  font-weight: 600;
  border-top-right-radius: 2rem;
  border-top-left-radius: 2rem;

  @media (max-width: 874px) {
    font-size: 1.8rem;
  }
`;

const StyledAppBar = styled(AppBar)`
  border-bottom-right-radius: 2rem;
  border-bottom-left-radius: 2rem;
  width: 100%;

  && {
    background: #e6e6e6;
    color: ${darkGrey};
  }
`;

const StyledTab = styled(Tab)`
  && {
    font-size: 1.1rem;
  }
`;

const TabContainer = ({ children }) => (
  <Typography component="div">
    {children}
  </Typography>
);

 const Special = ({
  tab,
  handleTabsChange
}) => {
  return (
    <Wrapper>
      <Container>
        <StyledH2>홍인K: 특별함</StyledH2>
        <Overview id="consult-explain">
          동남지구 최고 핵심상가! 최적의 투자와 프리미엄 상권으로 완벽한 성공의 기회에 주목하십시오!
        </Overview>
        <StyledDiv>
          <LocationImg>
            {tab === 0 && (
              <TabContainer>
                <Style style={{ background: '#bb4949' }}>
                  30,000여 세대의 <br />
                  배후수요 (항아리 상권)
                </Style>
              </TabContainer>
            )}
            {tab === 1 && (
              <TabContainer>
                <Style style={{ background: '#4e509a' }}>
                  가시성 &#183; 접근성 <br />
                  우수상가
                </Style>
              </TabContainer>
            )}
            {tab === 2 && (
              <TabContainer>
                <Style style={{ background: '#48b5b0' }}>
                  주요 상업지역의 <br />
                  프리미엄 메디컬상가
                </Style>
              </TabContainer>
            )}
            {tab === 3 && (
              <TabContainer>
                <Style style={{ background: '#35a934' }}>
                  신건축공법 <br />
                  원활한 공간활용
                </Style>
              </TabContainer>
            )}
            <StyledAppBar position="static">
              <Tabs
                value={tab}
                onChange={handleTabsChange}
                centered
              >
                <StyledTab label="하나." />
                <StyledTab label="둘." />
                <StyledTab label="셋." />
                <StyledTab label="넷." />
              </Tabs>
            </StyledAppBar>
          </LocationImg>
        </StyledDiv>
      </Container>
    </Wrapper>
  );
};

export default Special;
