import styled from 'styled-components';
import Image from 'next/image';

import { containerWidth } from '../styles/layout';
import {
  darkGrey,
  lightGrey
} from '../styles/colors';

const Wrapper = styled.div`
  border-top: 1px solid ${lightGrey};
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  width: ${containerWidth};
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
`;

const Title = styled.h2`
  font-size: 2rem;
  color: ${darkGrey};

  @media (max-width: 874px) {
    font-size: 1.5rem;    
  }

  @media (max-width: 320px) {
    font-size: 1rem;    
  }
`;

const Overview = styled.p`
  margin: 0 auto 3rem auto;
  font-size: 0.9rem;
  line-height: 1.4;
  color: ${darkGrey};

  @media (max-width: 480px) {
    font-size: 0.85rem;    
  }

  @media (max-width: 320px) {
    font-size: 0.7rem;    
  }
`;

const Box = styled.div`
  padding-bottom: 1rem;
  display: flex;
  justify-content: center;
  transition: box-shadow .5s ease-out;

  @media (max-width: 1150px) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }

  &:hover {
    box-shadow: 1px 1px 10px grey;
  }
`;

const StyledSpan = styled.span`
  padding: 1rem;
  background: #f7f7f7;
  border-radius: 0.25rem;
  border: 1px solid #b0b0b0;
`;

const Location = () => (
  <Wrapper>
    <Container>
      <Title>홍인K: 동남지구 최적의 위치</Title>
      <Overview>
        동남지구를 대표하는 프리미엄 메디컬 전문상가!
      </Overview>
      <Box>
        <StyledSpan>
          <Image src={'/jido.png'} width={500} height={550} />
        </StyledSpan>
      </Box>
    </Container>
  </Wrapper>
);

export default Location;
