import { Component } from 'react';
import styled from 'styled-components';

import { navHeight, containerWidth } from '../../styles/layout';
import SendEmail from './sendEmail';
import { darkGrey } from '../../styles/colors';

const Wrapper = styled.div`
  height: ${navHeight};
  width: 100%;
  display: flex;
  position: fixed;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #d4d5d7;
  background-color: white;
  z-index: 10000;
  transition: transform 300ms;

  &:hover {
    background-color: white;
  }
`;

const Container = styled.div`
  width: ${containerWidth};
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const LogoContainer = styled.div`
  font-size: 1.5rem;
  font-weight: 800;
  cursor: pointer;
  color: ${darkGrey};

  &:hover {
    transform: scale(1.1);
    transition: all .2s ease-in-out;
  }

  @media (max-width: 320px) {
    font-size: 1rem;    
  }
`;

const LogContainer = styled.div`
  display: flex;
  justify-content: flex-end;
`;

class Nav extends Component {
  state = {
    isOnTop: true,
    sticky: false,
  }

  handleMouseEnter = () => {
    this.setState({
      sticky: true,
    });
  }

  handleMouseLeave = () => {
    this.setState({
      sticky: false,
    });
  }

  handleToTop = () => {
    const { handleSubmit } = this.props;
    handleSubmit(null);
    document.body.scrollIntoView({ block: 'start', behavior: 'smooth' });
  };

  render() {
    const { sticky } = this.state;
    const { isSubmit, handleSubmit } = this.props;
    return (
      <Wrapper
        id="to-nav"
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        <Container>
          <LogoContainer onClick={this.handleToTop}>
            홍인K메디컬프라자
          </LogoContainer>
          <LogContainer>
            <SendEmail sticky={sticky} isSubmit={isSubmit} handleSubmit={handleSubmit} />
          </LogContainer>
        </Container>
      </Wrapper>
    );
  }
}

export default Nav;
