import { Component } from 'react';
import { createGlobalStyle } from 'styled-components';

import Layout from '../layout';
import Headline from '../components/headline';
import FloorPlan from '../components/floorPlan';
import Location from '../components/location';
import Special from '../components/special';

const CreateGlobal = createGlobalStyle`
  html, body {
    font-family: 'Nanum Gothic', sans-serif;
    height: 100%;
    margin: 0;
  }
  body > div:first-child, #__next, #__next > div {
    height: inherit;
  }
`;

class Main extends Component {
  state = {
    tab: 0,
    floorState: 0,
    isSubmit: null
  }

  handleTabsChange = (_, tab) => this.setState({ tab });

  handleFloorState = (_, floorState) => this.setState({ floorState });

  handleSubmit = isSubmit => this.setState({ isSubmit });
  
  render() {
    return (
      <Layout isSubmit={this.state.isSubmit} handleSubmit={this.handleSubmit}>
        <CreateGlobal />
        <Headline />
        <FloorPlan
          floorState={this.state.floorState}
          handleFloorState={this.handleFloorState}
        />
        <Special
          tab={this.state.tab}
          handleTabsChange={this.handleTabsChange}
        />
        <Location />
      </Layout>
    );
  }
}

export default Main;
