import styled from 'styled-components';
import Image from 'next/image';

import {
  darkGrey
} from '../../styles/colors';

const Wrapper = styled.div`
  margin-left: 1rem;
  margin-right: 1rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  font-size: 2rem;
  padding: 1rem;
  font-weight: 600;

  @media (max-width: 874px) {
    font-size: 1.5rem;    
  }
`;

const Box = styled.span`
  position: relative;
  padding: 1rem;
  background: white;
  border: 1px solid ${darkGrey};
  border-radius: 0.25rem;
  font-size: 0.9rem;
  max-width: 30rem;
  min-width: 25rem;
  height: 25rem;
  transition: box-shadow .5s ease-out;

  @media (max-width: 480px) {
    min-width: 10rem;
    font-size: 0.8rem;    
  }

  &:hover {
    box-shadow: 1px 1px 10px grey;
  }
`;

const AptCount = () => (
  <Wrapper>
    <Container>
      <Title>동남지구 아파트 세대수</Title>
      <Box>
        <Image src={'/saedaesu.png'} layout="fill" />
      </Box>
    </Container>
  </Wrapper>
);

export default AptCount;
