import styled from 'styled-components';
import Image from 'next/image';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 1rem;
  border: 1px solid #e6e6e6;
  margin-top: 1rem;
  border-radius: 0.25rem;
`;

const Container = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
`;

const FloorList = ({ floorState }) => (
  <Wrapper>
    <Container>
      {floorState === 0 && <Image src={`/1_floor.png`} width={1000} height={550} />}
      {floorState === 1 && <Image src={`/2_floor.png`} width={1000} height={550} />}
      {floorState === 2 && <Image src={`/3_floor.png`} width={1000} height={550} />}
      {floorState === 3 && <Image src={`/4_floor.png`} width={1000} height={550} />}
      {floorState === 4 && <Image src={`/5_floor.png`} width={1000} height={550} />}
      {floorState === 5 && <Image src={`/6_floor.png`} width={1000} height={550} />}
      {floorState === 6 && <Image src={`/7_floor.png`} width={1000} height={550} />}
      {floorState === 7 && <Image src={`/8_floor.png`} width={1000} height={550} />}
      {floorState === 8 && <Image src={`/9_floor.png`} width={1000} height={550} />}
      {floorState === 9 && <Image src={`pyung.png`} width={800} height={1000} />}
    </Container>
  </Wrapper>
);

export default FloorList;

