import Head from 'next/head'
import styled from 'styled-components';

import Nav from './nav';
import Footer from './footer';
import { navHeight } from '../styles/layout';

const Wrapper = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;

const Contents = styled.div`
  margin-top: ${navHeight};
  flex-grow: 1;
`;

const Layout = ({
  children,
  isSubmit,
  handleSubmit
}) => (
  <>
    <Head>
      <meta name="viewport" content="width=device-width, initial-scale=1" />
      <meta charSet="utf-8" />
      <meta name="description" content="동남지구 최고 핵심상가! 최적의 투자와 프리미엄 상권으로 완벽한 성공의 기회에 주목하십시오!" />
      <meta property="ko:title" content="홍인K" key="kotitle" />
      <title>홍인K</title>
    </Head>
    <Wrapper>
      <Nav isSubmit={isSubmit} handleSubmit={handleSubmit} />
      <Contents id="contents">{children}</Contents>
      <Footer isSubmit={isSubmit} handleSubmit={handleSubmit} />
    </Wrapper>
  </>
);

export default Layout;
