import styled from 'styled-components';

import { grey } from '../../styles/colors';
import { containerWidth } from '../../styles/layout';
import ContentsInfo from './contentsInfo';
import SendEmail from './sendEmail';
import APTInfo from './aptInfo';
import APTCount from './aptCount';

const Wrapper = styled.div`
  background: #f7f7f7;
`;

const OuterContainer = styled.div`
  display: flex;
  width: 100%;
  justify-content: center;
  padding-bottom: 2rem;
`;

const Container = styled.div`
  width: ${containerWidth};
  display: flex;
  justify-content: center;
  @media (max-width: 1150px) {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    align-items: center;
  }
`;

const BottomFooter = styled.footer`
  height: 15vh;
  display: flex;
  justify-content: center;
  
`;

const Copyright = styled.span`
  font-size: 0.8rem;
  width: ${containerWidth};
  border-top: 1px solid ${grey};
`;

const Footer = ({ isSubmit, handleSubmit }) => (
  <Wrapper>
    <OuterContainer>
      <Container>
        <SendEmail
          isSubmit={isSubmit}
          handleSubmit={handleSubmit}
        />
        <APTInfo />
        <APTCount />
      </Container>
    </OuterContainer>
    <BottomFooter>
      <Copyright>
        <ContentsInfo />
      </Copyright>
    </BottomFooter>
  </Wrapper>
);

export default Footer;
