import styled from 'styled-components';
import Image from 'next/image';

import { navHeight } from '../../styles/layout';
import { containerWidth } from '../../styles/layout';

import ToNext from './toNext';

const Wrapper = styled.div`
  margin-top: 0.5rem;
  display: flex;
  justify-content: center;
`;

const Container = styled.div`
  max-height: calc(100vh - ${navHeight} - 110px);
  height: calc(100vh - ${navHeight} - 110px);
  width: ${containerWidth};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  top: 0;

  @media (max-width: 786px) {
    max-height: 365px;
    height: 365px;
  }
`;

const StyledImg = styled(Image)`
  position: absolute;
  border-radius: 0.25rem;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
`;

const ToCryptoItems = styled.div`
  height: ${navHeight};
  z-index: -1;
  position: absolute;
  bottom: 0;
`;

const Headline = () => {
  return (
    <Wrapper>
      <Container id="headline">
        <StyledImg src="/the_hongink_2.jpg" layout='fill' />
        <ToNext />
        <ToCryptoItems id="to-companies" />
      </Container>
    </Wrapper>
  );
}

export default Headline;

