import { Fragment, useState } from 'react';
import styled from 'styled-components';
import DoneIcon from '@material-ui/icons/Done';
import ErrorIcon from '@material-ui/icons/Error';

import { darkGrey } from '../../styles/colors';
import Spinner from './spinner';

const Wrapper = styled.div`
  display: flex;
  margin-right: 1rem;
  margin-left: 1rem;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  font-size: 2rem;
  font-weight: 600;
  padding: 1rem;

  @media (max-width: 874px) {
    font-size: 1.5rem;    
  }
`;

const StyledForm = styled.form`
  border: 1px solid ${darkGrey};
  border-radius: 0.25rem;
  padding: 1rem;
  display: flex;
  width: 100%;
  background: white;
  max-width: 30rem;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-width: 25rem;
  transition: box-shadow .5s ease-out;

  @media (max-width: 480px) {
    min-width: 16.5rem;
  }

  @media (max-width: 786px) {
    min-width: 20rem;
  }

  &:hover {
    box-shadow: 1px 1px 10px grey;
  }
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 1rem;
`;

const RowCenter = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
  position: relative;
`;

const Label = styled.div`
  font-size: 0.8rem;
  font-weight: 600;
  color: rgb(119, 119, 119);
  margin-bottom: 0.5rem;
  color: ${darkGrey};
`;

const StyledInput = styled.input`
  width: 100%;
  padding: 0.5rem 0;
  outline: none;
  border: solid 0.05rem #dadada;
  border-radius: 0.2rem;

  &:hover {
    border: solid 0.05rem ${darkGrey};
  }
`;

const StyledTextArea = styled(StyledInput)`
  height: 6rem;
  resize: none;
`;

const SubmitButton = styled.input`
  font-size: 0.8rem;
  width: 5rem;
  height: 2.5rem;
  background: ${darkGrey};
  color: white;
  border-radius: 0.3rem;
  cursor: pointer;
  outline: none;
`;

const StyledSpan = styled.span`
  position: absolute;
  margin-left: 7rem;
  margin-top: 0.5rem;
  color: ${({ success }) => success ? 'green' : 'red'};
  font-size: 0.8rem;
`;

const StyledAfterSubmit = styled.div`
  height: 25.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const SendEmail = ({ handleSubmit, isSubmit }) => {
  const [isLoading, setIsLoading] = useState(false);
  return (
    <Wrapper id="to-send-email">
      <Container>
        <Title>
          문의하기
        </Title>
        <StyledForm
          onSubmit={async (event) => {
            try {
              event.preventDefault();
              if (isSubmit !== null) {
                handleSubmit(null);
                return;
              }
              setIsLoading(true);
              const data = {};
              ['name', 'phone', 'email', 'writings'].forEach((input) => {
              const { value } = document.getElementById(input);
              data[input] = value;
              });
              data.date = new Date();
              
              if (process.env.NODE_ENV === 'production') {
                await fetch(process.env.MAILER_PATH, {
                  body: JSON.stringify(data),
                  headers: {
                    'Content-Type': 'application/json'
                  },
                  mode: 'no-cors',
                  method: 'POST'
                });
                setIsLoading(false);
                handleSubmit(true);
              } else {
                setTimeout(() => {
                  console.log('전송완료')
                  setIsLoading(false);
                  handleSubmit(true);
                }, 50000)
              }
            } catch (err) {
              setIsLoading(false);
              handleSubmit(false);
            }
          }}
        >
          {isSubmit !== null ? (
            <StyledAfterSubmit>
              <Column>
                <Label>문의 사항 전송이 완료되었습니다. 빠른 시일내에 연락드리겠습니다.</Label>
              </Column>
              <RowCenter>
                <SubmitButton type="submit" value="요청완료" />
                {isSubmit ? (
                    <StyledSpan success>
                      <DoneIcon />
                    </StyledSpan>
                  ) : (
                    <StyledSpan>
                      <ErrorIcon />
                    </StyledSpan>
                  )
                }
              </RowCenter>
            </StyledAfterSubmit>
          ) : !isLoading ? (
            <Fragment>
              <Column>
                <Label>성함</Label>
                <StyledInput id="name" required />
              </Column>
              <Column>
                <Label>전화번호</Label>
                <StyledInput id="phone" type="number" required />
              </Column>
              <Column>
                <Label>이메일</Label>
                <StyledInput id="email" type="email" required />
              </Column>
              <Column>
                <Label>문의 사항: 남겨주시면 저희가 연락드리겠습니다.</Label>
                <StyledTextArea id="writings" required />
              </Column>
              <RowCenter>
                <SubmitButton type="submit" value="요청" />
              </RowCenter>
            </Fragment>
          ) : (
            <StyledAfterSubmit>
              <Spinner />
            </StyledAfterSubmit>
          )}
        </StyledForm>
      </Container>
    </Wrapper>
    
  );
};

export default SendEmail;
