import styled from 'styled-components';
import { Tabs, Tab, AppBar } from '@material-ui/core';
import { greyBlue } from '../styles/colors';
import { containerWidth } from '../styles/layout';
import FloorList from './floorList';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin: 2rem 0 2rem 0;
`;

const Container = styled.div`
  width: ${containerWidth};
  display: flex;
  align-items: center;
  flex-direction: column;
  border-raidus: 0.25rem;
`;

const StyledAppBar = styled(AppBar)`
  && {
    background-color: transparent;
    border: 1px solid #b0b0b0;
    border-radius: 0.25rem;
    box-shadow: 0px 2px 4px -1px rgb(0 0 0 / 1%), 0px 4px 5px 0px rgb(0 0 0 / 1%), 0px 1px 10px 0px rgb(0 0 0 / 1%);
    & > span: {
      max-width: 40;
      width: 100%;
      background-color: #635ee7;
    }
  }
`;

const StyledDiv = styled.div`
  width: 100%;
  height: 50px;
  display: flex;
  flex-grow: 1;
  font-size: 1.5rem;
  color: ${greyBlue};
`;

const StyledTab = styled(Tab)`
  && {
    font-size: 1.1rem;
  }
`;

const FLOORS = ['1층', '2층', '3층', '4층', '5층', '6층', '7층', '8층', '9층', '층별면적'];

const FloorPlan = ({ floorState, handleFloorState }) => {
  const handleClick = (e, floor) => {
    const y = window.pageYOffset;
    if (y <= 30) {
      document.getElementById('to-companies').scrollIntoView({ block: 'start', behavior: 'smooth' });
    }
    handleFloorState(e, floor);
  }
  return (
    <Wrapper>
      <Container>
        <StyledDiv>
          <StyledAppBar position="static" color="default">
            <Tabs
              value={floorState}
              onChange={handleClick}
              variant="scrollable"
              scrollButtons="on"
              indicatorColor="primary"
              textColor="primary"
              aria-label="scrollable force tabs example"
            >
              {FLOORS.map((floor, i) => (
                <StyledTab key={`floors-span-${i * 10}`} label={floor} />
              ))}
            </Tabs>
          </StyledAppBar>
        </StyledDiv>
        <FloorList floorState={floorState} />
      </Container>
    </Wrapper>
  );
}

export default FloorPlan;
