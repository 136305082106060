import styled from 'styled-components';

import { darkGrey } from '../../styles/colors';

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-left: 1rem;
  margin-right: 1rem;
  @media (max-width: 480px) {
    margin-bottom: 2rem;
  }
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Title = styled.span`
  font-size: 2rem;
  padding: 1rem;
  font-weight: 600;

  @media (max-width: 874px) {
    font-size: 1.5rem;    
  }
`;

const Box = styled.span`
  padding: 1rem;
  background: white;
  border: 1px solid ${darkGrey};
  border-radius: 0.25rem;
  font-size: 0.9rem;
  max-width: 30rem;
  min-width: 25rem;
  transition: box-shadow .5s ease-out;

  @media (max-width: 480px) {
    min-width: 10rem;
    font-size: 0.8rem;    
  }

  &:hover {
    box-shadow: 1px 1px 10px grey;
  }
`;

const LineBox = styled.div`
  display: flex;
`;

const Key = styled.span`
  flex: 0.2;
  text-align: center;;
  &:after {
    content: "";
    display: inline-block;
    width: 100%;
  }

  @media (max-width: 320px) {
    font-size: 0.6rem;    
  }
`;

const Divider = styled.span`
  margin: 0 0.5rem;
`;

const Value = styled.span`
  flex: 0.8;
  font-weight: 600;
  padding-left: 0.5rem;

  @media (max-width: 320px) {
    font-size: 0.6rem;  
  }
`;

const AptInfo = () => (
  <Wrapper>
    <Container>
      <Title>
        건축 개요
      </Title>
      <Box>
        <LineBox>
          <Key>대지위치</Key>
          <Divider>|</Divider>
          <Value>청주시 상당구 동남지구 상업1블럭2로트</Value>
        </LineBox>
        <LineBox>
          <Key>지역지구</Key>
          <Divider>|</Divider>
          <Value>일반상업용지, 지구단위계획 구역</Value>
        </LineBox>
        <LineBox>
          <Key>주용도</Key>
          <Divider>|</Divider>
          <Value>근린생활시설</Value>
        </LineBox>
        <LineBox>
          <Key>주차대수</Key>
          <Divider>|</Divider>
          <Value>자동차 45대, 자전거 10대</Value>
        </LineBox>
        <LineBox>
          <Key>건축면적</Key>
          <Divider>|</Divider>
          <Value>812.33m<sup>2</sup></Value>
        </LineBox>
        <LineBox>
          <Key>건패율</Key>
          <Divider>|</Divider>
          <Value>78.71%</Value>
        </LineBox>
        <LineBox>
          <Key>규모</Key>
          <Divider>|</Divider>
          <Value>지하2층~지상9층</Value>
        </LineBox>
        <LineBox>
          <Key>연면적</Key>
          <Divider>|</Divider>
          <Value>8,578.67m<sup>2</sup></Value>
        </LineBox>
        <LineBox>
          <Key>용적률</Key>
          <Divider>|</Divider>
          <Value>657.30%</Value>
        </LineBox>
      </Box>
    </Container>
  </Wrapper>
);

export default AptInfo;
